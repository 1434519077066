<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <div class="form-wrapper full">
        <!-- Organization -->
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <organization-selector
            :organization="property.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId, organization) => {
                property.organization.id = organizationId;
                property.allowedLocations = [];
                onFormChanged();
              }
            "
            :disabled="!!property.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.LOCATION')}`"
          :placeholder="$t('COMMON.LOCATION')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        >
          <locations-selector
            :locations="property.allowedLocations"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :organization="property.organization.id"
            @locationsChanged="
              (locations) => {
                property.allowedLocations = locations;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.allowedLocations" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.OWNERS')}`"
          :placeholder="$t('COMMON.OWNERS')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
        >
          <contacts-selector
            :placeholder="$t('COMMON.OWNERS')"
            :contacts="property.owners"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :filterOrganization="property.organization.id"
            @contactsChanged="
              (contacts) => {
                property.owners = contacts;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.owners" />
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES)"
      >
        <base-input
          :label="`${$t('COMMON.AUCTION')}`"
          :placeholder="$t('COMMON.AUCTION')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_AUCTIONS)"
        >
          <auction-selector
            :placeholder="$t('COMMON.AUCTION')"
            :auction="property?.auction?.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="property?.organization?.id"
            @auctionChanged="
              (auctionId) => {
                property.auction.id = auctionId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.auction" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('PROPERTIES.DESIGNATION')} (*)`"
          :placeholder="$t('PROPERTIES.DESIGNATION')"
        >
          <textarea
            v-model="property.designation"
            :placeholder="$t('PROPERTIES.DESIGNATION')"
            class="form-control"
            rows="3"
            @change="onFormChanged"
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.designation" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.TYPE')} (*)`"
          :placeholder="$t('PROPERTIES.TYPE')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            :placeholder="$t('PROPERTIES.TYPE')"
            v-model="property.property_type"
            @change="onFormChanged"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in PROPERTY_TYPES"
              :key="key"
              :label="$t(`PROPERTIES.PROPERTIES_TYPE_${item}`)"
              :value="item"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.property_type" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.REGISTRATION_NUMBER')} (*)`"
          :placeholder="$t('PROPERTIES.REGISTRATION_NUMBER')"
          v-model="property.registration_number"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.registration_number" />
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES)"
      >
        <base-input
          :label="`${$t('PROPERTIES.SOLD_AT')}`"
          :placeholder="$t('PROPERTIES.SOLD_AT')"
          v-model="property.sold_at"
          @change="onFormChanged"
        >
          <flat-picker
            :placeholder="$t('PROPERTIES.SOLD_AT')"
            :config="{
              allowInput: true,
              enableTime: false,
              type: 'date',
              maxDate: 'today',
              locale: $flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }"
            class="form-control datepicker"
            v-model="property.sold_at"
            @on-change="onFormChanged()"
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.sold_at" />
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES)"
      >
        <base-input
          :label="`${$t('PROPERTIES.SOLD_AMOUNT')}`"
          :placeholder="$t('PROPERTIES.SOLD_AMOUNT')"
          v-model="property.sold_amount"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.sold_amount" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.STATUS')} (*)`"
          :placeholder="$t('COMMON.STATUS')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            v-model="property.status"
            @change="
              (value) => {
                if (value !== STATUS_CANCEL) {
                  property.cancel_reason = null;
                }
                onFormChanged();
              }
            "
            :placeholder="$t('COMMON.STATUS')"
          >
            <template v-for="(item, key) in PROPERTY_STATUS">
              <el-option
                v-if="limitedStatus.includes(item)"
                class="select-primary"
                :key="key"
                :label="$t(`PROPERTIES.PROPERTIES_STATUS_${item}`)"
                :value="item"
              >
              </el-option>
            </template>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.status" />
      </div>

      <div class="form-wrapper full" v-if="property.status === STATUS_CANCEL">
        <base-input
          :label="`${$t('PROPERTIES.CANCEL_REASON')} (*)`"
          :placeholder="$t('PROPERTIES.CANCEL_REASON')"
        >
          <textarea
            v-model="property.cancel_reason"
            :placeholder="$t('PROPERTIES.CANCEL_REASON')"
            class="form-control"
            rows="3"
            @change="onFormChanged"
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.cancel_reason" />
      </div>

      <div class="form-wrapper full">
        <batch-numbers-inputs
          :label="`${$t('PROPERTIES.BATCH_NUMBER')} (*)`"
          :batchNumbers="property.batch_numbers"
          @batchNumbersChanged="
            (batchNumbers) => {
              property.batch_numbers = batchNumbers;
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.batch_numbers" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.PROPERTIES_TAXES_DUE_SCHOOL')}`"
          :placeholder="$t('PROPERTIES.PROPERTIES_TAXES_DUE_SCHOOL')"
          v-model="property.owed_taxes_school_board"
          @change="onFormChanged"
        />
        <validation-error
          :errors="apiValidationErrors.owed_taxes_school_board"
        />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.PROPERTIES_TAXES_DUE_MUNICIPAL')}`"
          :placeholder="$t('PROPERTIES.PROPERTIES_TAXES_DUE_MUNICIPAL')"
          v-model="property.owed_taxes_municipality"
          @change="onFormChanged"
        />
        <validation-error
          :errors="apiValidationErrors.owed_taxes_municipality"
        />
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES)"
      >
        <base-checkbox
          @change="onFormChanged"
          v-model="property.taxable"
          class="mb-3"
        >
          <span class="form-control-label">
            {{ $t("COMMON.TAXABLE") }}
          </span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.taxable" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.ACQUISITION_NUMBER')} (*)`"
          :placeholder="$t('PROPERTIES.ACQUISITION_NUMBER')"
          v-model="property.acquisition_number"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.acquisition_number" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.ACQUISITION_METHODE')} (*)`"
          :placeholder="$t('PROPERTIES.ACQUISITION_METHODE')"
          v-model="property.acquisition_method"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.acquisition_method" />
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES)"
      >
        <base-input
          :label="`${$t('PROPERTIES.PROPERTY_NUMBER')}`"
          :placeholder="$t('PROPERTIES.PROPERTY_NUMBER')"
          v-model="property.property_number"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.property_number" />
      </div>

      <div class="form-wrapper full">
        <addresses-selector
          @addressInputChangeChanged="
            (values) => {
              property = {
                ...property,
                ...values,
              };
              onFormChanged();
            }
          "
        />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.ADDRESS')} (*)`"
          inputClasses="text-uppercase"
          style="text-transform: uppercase"
          v-model="property.address"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.address" />
      </div>

      <div class="form-wrapper">
        <base-input
          inputClasses="text-uppercase"
          :label="`${$t('COMMON.CITY')} (*)`"
          v-model="property.city"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.city" />
      </div>

      <div class="form-wrapper">
        <base-input
          inputClasses="text-uppercase"
          :label="`${$t('COMMON.ZIPCODE')}`"
          v-model="property.zipcode"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.STATE')} (*)`"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            inputClasses="text-uppercase"
            :country="property.country"
            :state="property.state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                property.state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.state" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.COUNTRY')} (*)`"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            inputClasses="text-uppercase"
            :country="property.country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                property.country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>

      <div class="form-wrapper full">
        <!-- Excerpt -->
        <base-input
          :label="`${$t('COMMON.NOTE')}`"
          :placeholder="$t('COMMON.NOTE')"
        >
          <html-editor v-model="property.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>

      <div class="submit-wrapper">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{
            property.id
              ? $t("PROPERTIES.EDIT_PROPERTY")
              : $t("PROPERTIES.ADD_PROPERTY")
          }}
        </base-button>
      </div>
    </form>

    <modal :show="showModal" modal-classes="modal-secondary" size="sm">
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="text-center mt--4">
          {{ $t("PROPERTIES.CHANGE_READY_STATUS") }}
        </div>
        <div class="text-center">
          <strong class="text-lowercase">
            {{
              $t("PROPERTIES.CONFIRMATION_QUESTION", {
                status: $t(`PROPERTIES.PROPERTIES_STATUS_${property.status}`),
              })
            }}
          </strong>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleModalState"
          :disabled="loading"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import ContactsSelector from "@/components/ContactsSelector.vue";
import AuctionSelector from "@/components/AuctionSelector";
import BatchNumbersInputs from "@/components/BatchNumbersInputs.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  PROPERTY_STATUS,
  PROPERTY_TAXES,
  PROPERTY_TYPES,
  STATUS_ACTIVE,
  STATUS_APPROVED,
  STATUS_CANCEL,
  STATUS_CONFIRMED,
  STATUS_PENDING,
} from "@/constants/properties";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CountrySelector,
    AuctionSelector,
    flatPicker,
    StateSelector,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    HtmlEditor,
    LocationsSelector,
    ContactsSelector,
    BatchNumbersInputs,
    AddressesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["propertyData", "formErrors", "loading"],

  data() {
    let propertyData = { ...this.propertyData };
    propertyData = this.$fillUserOrganizationData(propertyData);
    return {
      property: propertyData,
      oldStatus: this.propertyData?.status,
      PROPERTY_STATUS,
      PROPERTY_TYPES,
      PROPERTY_TAXES,
      STATUS_CANCEL,
      showModal: false,
      limitedStatus: this.$currentUserCan(
        this.$permissions.PERM_CHANGE_APPROVED_STATUS_PROPERTIES
      )
        ? PROPERTY_STATUS
        : [STATUS_PENDING, STATUS_CANCEL, STATUS_APPROVED],
    };
  },

  created() {},

  methods: {
    async continueFormSubmitProcess() {
      this.toggleModalState();
      await this.submitInformation();
    },

    toggleModalState() {
      this.showModal = !this.showModal;
    },

    async submitInformation() {
      if (this.property.address) {
        this.property.address = this.property.address.toString().toUpperCase();
      }
      if (this.property.state) {
        this.property.state = this.property.state.toString();
      }
      if (!this.property?.auction?.id) {
        delete this.property.auction;
      }
      if (this.property.zipcode) {
        this.property.zipcode = this.property.zipcode.toString().toUpperCase();
      }

      const batch_numbers = this.property.batch_numbers.map((batch) => {
        return {
          value: parseInt(batch?.value?.toString().replaceAll(" ", ""), 10),
        };
      });

      this.property.sold_at = this.$convertDateToUtc(this.property.sold_at);
      let propertyData = cloneDeep({ ...this.property, batch_numbers });
      propertyData = this.$fillUserOrganizationData(propertyData);
      this.$emit("propertySubmitted", propertyData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
    async handleSubmit() {
      if (
        (this.oldStatus === STATUS_CONFIRMED ||
          this.oldStatus === STATUS_ACTIVE) &&
        (this.property.status === STATUS_CANCEL ||
          this.property.status === STATUS_PENDING ||
          this.property.status === STATUS_APPROVED)
      ) {
        this.toggleModalState();
        return 0;
      } else {
        await this.submitInformation();
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    propertyData(propertyData) {
      if (propertyData) {
        this.oldStatus = propertyData?.status;
        this.property = {
          ...this.property,
          ...cloneDeep(propertyData),
        };
      }
    },
  },
};
</script>
