<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <div v-if="property && !loading" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <property-view-global :property="property" @propertyUpdated="get" />
        </tab-pane>
        <tab-pane title="global" id="2">
          <span slot="title">
            <i class="ni ni-circle-08"></i>
            {{ $t("COMMON.OWNERS") }}
          </span>
          <property-contact-list-table
            :property="property"
            :filterProperty="property?.id"
          />
        </tab-pane>
        <tab-pane
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_TRANSACTIONS_PROPERTIES)
          "
          title="global"
          id="3"
        >
          <span slot="title">
            <i class="fas fa-money-bill"></i>
            {{ $t("COMMON.PAYMENTS") }}
          </span>
          <payment-list-table
            :refreshView="get"
            :property="property"
            :filterProperty="property?.id"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="property" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <property-view-logs :property="property" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import PropertyViewGlobal from "../partials/PropertyViewGlobal.vue";
import PropertyViewLogs from "../partials/PropertyViewLogs.vue";
import PropertyContactListTable from "../partials/PropertyContactListTable.vue";
import PaymentListTable from "../partials/PropertyTransactionListTable.vue";

export default {
  layout: "view-property-component",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PropertyViewGlobal,
    PropertyViewLogs,
    ListFileComponent,
    PropertyContactListTable,
    PaymentListTable,
  },

  mixins: [],

  props: {
    propertyId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      property: null,
      loading: true,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;
        await this.$store.dispatch("properties/get", this.propertyId);
        this.property = this.$store.getters["properties/property"];
        this.loading = false;
        this.$emit("propertyChange", this.property);
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
