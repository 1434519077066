import { TRANSACTION_TYPE_PAYMENT } from "@/constants/properties";

export default {
  type: "property-transactions",
  amount: 0,
  transaction_excerpt: null,
  method_payment: null,
  transaction_date: null,
  transaction_type: TRANSACTION_TYPE_PAYMENT,
  relationshipNames: ["property"],
  property: {
    type: "properties",
    id: null,
  },
};
